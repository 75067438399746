import React, { Component } from 'react';
// import logo from './logo.svg';
// import icon from './AppIcon-512.png';
// import { BrowserRouter, Route } from 'react-router-dom';
import {
  createBrowserRouter,
  Link,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import './App.css';
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getStorage, ref as storageRef, getDownloadURL, getBlob } from "firebase/storage";
import { getDatabase, ref, child, get, onValue } from "firebase/database";
import { ScaleControl, NavigationControl, GeolocateControl, Marker, Layer, Source, Popup } from 'react-map-gl';
import ReactMapGL from "react-map-gl";
import mapboxgl from "mapbox-gl";
import type { MapRef, LineLayer, SkyLayer, SymbolLayer } from 'react-map-gl';
import type { FeatureCollection, Feature } from 'geojson';
import 'mapbox-gl/dist/mapbox-gl.css';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
(mapboxgl as any).workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
var convert = require('convert-units')

// https://outworld-web-dev.web.app/maps/\(teamID)

interface Props {
  
}

interface RTFile {
  key: string;
  fileName: string;
  timestamp: number;
  author: string;
}

interface StorageFile {
  key: string;
  fileName: string;
  author: string;
  geoJSON: FeatureCollection;
}

interface TeammateLocation {
  heading: number;
  lat: number;
  long: number;
  speed: number;
  timestamp: number;
}

interface Teammate {
  name: string;
  location: TeammateLocation;
}

interface State {
  teamID: String;
  teamNameLoaded: boolean;
  teamName: string;
  skyLayer: SkyLayer;
  geoJSONElements: React.ReactElement[];
  features: Feature[];
  featureElements: React.ReactElement[];
  rtFiles: RTFile[];
  storageFiles: StorageFile[];
  mapboxSourceIDs: string[];
  test: boolean;
  teammates: Teammate[];
  teammateFeatures: Feature[];
  teammateElements: React.ReactElement[];
}

const firebaseConfig = {
  apiKey: "AIzaSyDwvhPdNaf2cPozTcN3pa8ydtFGE8O956w",
  authDomain: "offworld-39884.firebaseapp.com",
  databaseURL: "https://offworld-39884-default-rtdb.firebaseio.com",
  projectId: "offworld-39884",
  storageBucket: "offworld-39884.appspot.com",
  messagingSenderId: "866516903999",
  appId: "1:866516903999:web:ec658842020908c9b4308a",
  measurementId: "G-7XW0ML1DT2"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// const database = getDatabase(app);

class App extends Component<Props, State> {

  public state = {
    teamID: "-Ndmq_W-T7RgYlpXLpT6",
    teamNameLoaded: false,
    teamName: '',
    skyLayer: ({
      'id': 'sky',
      'type': "sky",
      'paint': {
        'sky-type': 'atmosphere',
        'sky-atmosphere-sun': [0.0, 0.0],
        'sky-atmosphere-sun-intensity': 15
      }
    } as SkyLayer),
    geoJSONElements: [] as React.ReactElement[],
    features: [] as Feature[],
    featureElements: [] as React.ReactElement[],
    rtFiles: [] as RTFile[],
    storageFiles: [] as StorageFile[],
    mapboxSourceIDs: [] as string[],
    test: true,
    teammates: [] as Teammate[],
    teammateFeatures: [] as Feature[],
    teammateElements: [] as React.ReactElement[]
  }

  private mapRef = React.createRef<MapRef>();

  private onMapLoad = () => {
    const {current: map} = this.mapRef;
    if (map) {
      map.loadImage(
        "./marker-icons/mappin.png", 
        (error, image) => {
          if (error) throw error;

          if (map && image) {
            map.addImage('mappin', image)
          }
        }
      )
      map.loadImage(
        "./marker-icons/teammate.png",
        (error, image) => {
          if (error) throw error;

          if (map && image) {
            map.addImage('teammate', image)
          }
        }
      )
    }
  };

  public componentDidMount = () => {

    this.retrieveTeamName()
    this.retrieveFeatures()
    this.retrieveTeammates()
    this.retrieveFilesList()

  }

  private generateFeatureElements = () => {

    const { features } = this.state
    let featureElements: React.ReactElement[] = []

    features.forEach((feature, idx) => {

      if (feature.geometry.type === "Point") {

        if (feature.properties && feature.properties.type === "marker-place") {

          const popup = 
            <Popup 
              longitude={feature.geometry.coordinates[0]} 
              latitude={feature.geometry.coordinates[1]}
              anchor="top"
            >
              {feature.properties.name}
            </Popup>

          const element = 
            <Marker 
              longitude={feature.geometry.coordinates[0]} 
              latitude={feature.geometry.coordinates[1]} 
              anchor="bottom"
              key={`feature-place-marker${idx}`}
            >
              <img 
                src="./location-pin.png" 
                width="55" 
                height="55" 
                alt="marker place"
              />
              {popup}
            </Marker>
          featureElements.push(element)

        } else if (feature.properties && feature.properties.type.substring(0, 7) === "marker-") {

          const element = 
            <Marker 
              longitude={feature.geometry.coordinates[0]} 
              latitude={feature.geometry.coordinates[1]} 
              anchor="center"
              key={`feature-marker${idx}`}
            >
              <img 
                src={this.markerIconFromType(feature.properties.type)} 
                width={this.markerWidthFromType(feature.properties.type)}
                height={this.markerHeightFromType(feature.properties.type)}
                alt="marker pin"
              />
            </Marker>
          featureElements.push(element)

        }

      } else if (feature.geometry.type === "LineString") {

        if (feature.properties) {

          const lineStringGeoJSON = {
            "features": [{
            "type": "Feature",
            "properties": {
                "name": "Lot 3161",
                "strokeColor": this.hexColorFromString(feature.properties.color)
            },
            "geometry": {
              "type": "LineString",
              "coordinates": feature.geometry.coordinates
            }
          }],
            "type": "FeatureCollection"
          } as FeatureCollection

          const lineStringLayerStyle = {
            id: `feature-line-string${idx}`,
            type: "line",
            paint: {
              'line-width': 16,
              'line-color': this.hexColorFromString(feature.properties.color)
            }
          } as LineLayer

          const element = 
            <Source 
              id={`feature-line-string${idx}`}
              type="geojson" 
              data={lineStringGeoJSON} 
              key={`feature-line-string${idx}`}
            >
              <Layer {...lineStringLayerStyle} />
            </Source>
          featureElements.push(element)

        }
      }
    })

    this.setState({ featureElements: featureElements })
  }

  private hexColorFromString = (string: string) => {
    if (string === "yellow") {
      return "#FFFF00"
    } else if (string === "red") {
      return "#FF0000"
    } else if (string === "gray") {
      return "#808080"
    } else if (string === "orange") {
      return "#FFAC1C"
    } else if (string === "green") {
      return "#00FF00"
    } else if (string === "blue") {
      return "#0000FF"
    } else if (string === "purple") {
      return "#800080"
    } else if (string === "brown") {
      return "#966919"
    } else if (string === "black") {
      return "#000000"
    } else if (string === "white") {
      return "#FFFFFF"
    } else {
      return "#FFFF00"
    }
  }

  private markerIconFromType = (type: string) => {

    const icon = type.split('marker-')[1]

    return `./marker-icons/${icon}.png`
  }

  private markerWidthFromType = (type: string) => {
    if (type === "marker-pin") {
      return 35
    } else if (type === "marker-exclamationmark.triangle.fill") {
      return 50
    } else if (type === "marker-exclamationmark.octagon.fill") {
      return 45
    } else if (type === "marker-car.fill") {
      return 45
    } else if (type === "marker-box.truck.fill") {
      return 50
    } else if (type === "marker-tree.fill") {
      return 45
    } else if (type === "marker-airplane") {
      return 45
    } else if (type === "marker-mountain.2.fill") {
      return 75
    } else {
      return 0
    }
  }

  private markerHeightFromType = (type: string) => {
    if (type === "marker-pin") {
      return 45
    } else if (type === "marker-exclamationmark.triangle.fill") {
      return 45
    } else if (type === "marker-exclamationmark.octagon.fill") {
      return 45
    } else if (type === "marker-car.fill") {
      return 40
    } else if (type === "marker-box.truck.fill") {
      return 40
    } else if (type === "marker-tree.fill") {
      return 45
    } else if (type === "marker-airplane") {
      return 45
    } else if (type === "marker-mountain.2.fill") {
      return 45
    } else {
      return 0
    }
  }

  private retrieveFeatures = () => {

    const { teamID } = this.state
    const db = getDatabase();

    return onValue(ref(db, `teams/${teamID}/features`), (snapshot) => {

      if (snapshot.exists()) {

        console.log(`retrieved features`)

        const snapshotFeatures = snapshot.val() as Object

        let features: Feature[] = []

        Object.values(snapshotFeatures).forEach((snapshotFeature: any) => {

          const snapshotFeatureType = snapshotFeature["type"]

          if (snapshotFeatureType === "marker-place") {

            const snapshotFeatureCoords = snapshotFeature["coordinates"][0]
            const feature = {
              "type": "Feature",
              "properties": {
                  "name": snapshotFeature["name"],
                  "title": snapshotFeature["name"],
                  "type": snapshotFeatureType
              },
              "geometry": {
                "type": "Point",
                "coordinates": [snapshotFeatureCoords[1] as number, snapshotFeatureCoords[0] as number]
              }
            } as Feature
            features.push(feature)

          } else if (snapshotFeatureType.substring(0, 7) === "marker-") {

            const snapshotFeatureCoords = snapshotFeature["coordinates"][0]
            const feature = {
              "type": "Feature",
              "properties": {
                  "name": "WPT",
                  "title": "WPT",
                  "type": snapshotFeatureType
              },
              "geometry": {
                "type": "Point",
                "coordinates": [snapshotFeatureCoords[1] as number, snapshotFeatureCoords[0] as number]
              }
            } as Feature
            features.push(feature)

          } else if (snapshotFeatureType === "polyline") {
            const snapshotFeatureCoords = snapshotFeature["coordinates"]
            const coordinates = snapshotFeatureCoords.map((coord: number[]) => [coord[1], coord[0]])
            const feature = {
              "type": "Feature",
              "properties": {
                  "name": "Line",
                  "color": snapshotFeature["color"]
              },
              "geometry": {
                "type": "LineString",
                "coordinates": coordinates
              }
            } as Feature
            features.push(feature)
          }
        })

        this.setState({ 
          features: features,
          featureElements: []
        }, () => this.generateFeatureElements())

      }
      
    }, {
      onlyOnce: false
    });
  }

    private generateTeammateElements = () => {

    const { teammateFeatures } = this.state
    let teammateElements: React.ReactElement[] = []
    let fileElements: React.ReactElement[] = []
    let mapboxSourceIDs: string[] = []

    // teammateFeatures.forEach((teammateFeature, idx) => {

    //   if (teammateFeature.properties) {
    //     const popup = 
    //       <Popup 
    //         longitude={teammateFeature.geometry.coordinates[0]} 
    //         latitude={teammateFeature.geometry.coordinates[1]}
    //         anchor="top"
    //       >
    //         {teammateFeature.properties.name}
    //       </Popup>

    //     const element = 
    //       <Marker 
    //         longitude={teammateFeature.geometry.coordinates[0]} 
    //         latitude={teammateFeature.geometry.coordinates[1]} 
    //         anchor="bottom"
    //         key={`feature-place-marker${idx}`}
    //       >
    //         <img src="./location-pin.png" width="45" height="45" alt="teammate"/>
    //         {popup}
    //       </Marker>
    //     teammateElements.push(element)

    //   }
    // })

      const teammateGeoJSON = {
        "features": teammateFeatures,
        "type": "FeatureCollection"
      } as FeatureCollection

      const sourceID = 'teammate'

      const symbolLayerStyle = {
        id: sourceID,
        type: "symbol",
        layout: {
          'icon-image': 'teammate',
          'icon-size': 0.5,
          'icon-anchor': 'center',
          'text-field': ['get', 'label'],
          "text-size": 20,
          "text-anchor": 'top',
          'text-offset': [0, 1.5],
          "icon-rotate": ['get', 'heading'] // { "type": "identity", "property": "rotation" }  //   // 
        },
        paint: {
          'text-halo-width': 4,
          'text-halo-color': '#FFFFFF'
        }
      } as SymbolLayer

      const teammateElement = 
        <Source 
          id={sourceID}
          type="geojson" 
          data={teammateGeoJSON} 
          key={sourceID}
        >
          <Layer {...symbolLayerStyle} />
        </Source>

    

    mapboxSourceIDs.push(sourceID)
    teammateElements.push(teammateElement)

    this.setState({ 
      mapboxSourceIDs: this.state.mapboxSourceIDs.concat(mapboxSourceIDs),
      teammateElements: this.state.teammateElements.concat(teammateElements) 
    })
  }

  private retrieveTeammates = () => {

    const { teamID } = this.state
    const db = getDatabase();

    return onValue(ref(db, `teams/${teamID}/info/members`), (snapshot) => {

      if (snapshot.exists()) {

        console.log(`retrieved teammates`)

        const snapshotTeammates = snapshot.val() as Object

        let teammateFeatures: Feature[] = []

        Object.values(snapshotTeammates).forEach((snapshotTeammate: any) => {

            const snapshotTeammateLocation = snapshotTeammate["location"]
            const speed = Math.round(convert(snapshotTeammateLocation["speed"]).from('m').to('mi') * 3600) // m/s to mph

            const teammateFeature = {
              "type": "Feature",
              "properties": {
                  "name": snapshotTeammate["name"],
                  "title": snapshotTeammate["name"],
                  "label": `${snapshotTeammate["name"]}  ${speed}mph`,
                  "heading": (snapshotTeammateLocation["heading"] as number)
              },
              "geometry": {
                "type": "Point",
                "coordinates": [
                  snapshotTeammateLocation["long"] as number, 
                  snapshotTeammateLocation["lat"] as number
                ]
              }
            } as Feature
            teammateFeatures.push(teammateFeature)

            console.log(teammateFeature)

        })

        this.setState({ 
          teammateFeatures: teammateFeatures,
          teammateElements: []
        }, () => this.generateTeammateElements())

      }
      
    }, {
      onlyOnce: false
    });
  }

  private retrieveFilesList = () => {

    
    const { teamID, mapboxSourceIDs } = this.state
    const db = getDatabase();

    // remove layer then source

    // this.setState({ 
    //   rtFiles: [],
    //   storageFiles: [],
    //   geoJSONElements: [],
    // }, () => {

      onValue(ref(db, `teams/${teamID}/files`), (snapshot) => {

        if (snapshot.exists()) {

          const {current: map} = this.mapRef;
          if (map) {
            // console.log(this.state.mapboxSourceIDs)
            this.state.mapboxSourceIDs.forEach((sourceID) => {

              const currentMap = map.getMap()
              if (currentMap.getLayer(sourceID)) {
                currentMap.removeLayer(sourceID);  
                // console.log(`removed layer ${sourceID}`)
              }
              if (currentMap.getSource(sourceID)) {
                currentMap.removeSource(sourceID)
                // console.log(`removed source ${sourceID}`)
              }

              // if (map.getLayer(sourceID)) {
              //   const layer = map.getLayer(sourceID)
              //   map.removeLayer(layer)
              // }
            })
          } else {
            console.log(this.mapRef)
          }

          this.setState({ 
            rtFiles: [],
            storageFiles: [],
            geoJSONElements: [],
            mapboxSourceIDs: [] }, () => {

          // console.log(snapshot.val())

            let rtFiles: RTFile[] = []
            const snapshotFiles = snapshot.val() as any

            Object.keys(snapshot.val()).forEach((key) => {

              const snapshotFile = snapshotFiles[key]

              const rtFile = {
                key: key,
                fileName: snapshotFile["fileName"],
                timestamp: snapshotFile["timestamp"],
                author: snapshotFile["userID"]
              } as RTFile

              rtFiles.push(rtFile)
            })

            this.setState({ rtFiles }, () => this.retrieveFiles())

          })
        }
        
      }, {
        onlyOnce: false
      });

    // })
  }

  private retrieveTeamName = () => {

    const { teamID } = this.state
    const dbRef = ref(getDatabase());

    get(child(dbRef, `teams/${teamID}/info/name`)).then((snapshot) => {

      if (snapshot.exists()) {

        const teamName = snapshot.val()

        this.setState({
          teamName: teamName,
          teamNameLoaded: true
        })

      } else {
        console.log("No data available");
      }

    }).catch((error) => console.error(error));

  }

  private retrieveGeoJSON = () => {

    const storage = getStorage();

    getDownloadURL(storageRef(storage, 'outworld-web-dev/rr-property-boundaries.json'))
      .then((url) => {

        const xhr = new XMLHttpRequest();
        xhr.responseType = 'json';
        xhr.onload = (event) => {

          const geoJSON = xhr.response as FeatureCollection

          let geoJSONElements: React.ReactElement[] = []

          geoJSON.features.forEach((feature, idx) => {

            if (feature.geometry.type === "LineString") {

              const lineColor = feature.properties && feature.properties.strokeColor
                ? feature.properties.strokeColor
                : this.hexColorFromString("green")

              const lineName = feature.properties && feature.properties.name
                ? feature.properties.name
                : ''

               const lineStringGeoJSON = {
                "features": [{
                "type": "Feature",
                "properties": {
                    "name": lineName,
                    "strokeColor": lineColor
                },
                "geometry": {
                  "type": "LineString",
                  "coordinates": feature.geometry.coordinates
                }
              }],
                "type": "FeatureCollection"
              } as FeatureCollection

              const lineStringLayerStyle = {
                id: `geojson-line-string${idx}`,
                type: "line",
                paint: {
                  'line-width': 3,
                  'line-color': lineColor
                }
              } as LineLayer

              const geoJSONElement = 
                <Source 
                  id={`geojson-line-string${idx}`}
                  type="geojson" 
                  data={lineStringGeoJSON} 
                  key={`geojson-line-string${idx}`}
                >
                  <Layer {...lineStringLayerStyle} />
                </Source>
              geoJSONElements.push(geoJSONElement)

            }

          })

          const idx = geoJSON.features.length

          const symbolLayerStyle = {
            id: `file-circle${idx}`,
            type: "symbol",
            layout: {
              'text-field': ['get', 'name'],
              "text-size": 18,
              "text-anchor": "top",
            },
            paint: {
              'text-halo-width': 4,
              'text-halo-color': '#FFFFFF'
            }
          } as SymbolLayer

          const circleElement = 
            <Source 
              id={`file-circle${idx}`}
              type="geojson" 
              data={geoJSON} 
              key={`file-circle${idx}`}
            >
              <Layer {...symbolLayerStyle} />
            </Source>
           geoJSONElements.push(circleElement)

          this.setState({ geoJSONElements: this.state.geoJSONElements.concat(geoJSONElements) })

        };
        xhr.open('GET', url);
        xhr.send();

      })
      .catch((error) => {
        console.log(error)
      });
  }

  private retrieveFiles = () => {

    const { teamID, rtFiles, test } = this.state

    const storage = getStorage();

    // if (test) {

      // this.setState({ test: false })

    rtFiles.forEach((rtFile, rtIdx) => {

    // getBlob(storageRef(storage, 'outworld-web-dev/brr-array-buffer'), 20 * 1024 * 1024)
      getBlob(storageRef(storage, `${teamID}/${rtFile.key}`), 20 * 1024 * 1024)
        .then((blob) => {

          blob.text().then((blobString) => {

            // console.log(blobString)
            const blobJSON = JSON.parse(blobString) as FeatureCollection

            console.log(`retrieved ${rtFile.fileName}`)

            let fileElements: React.ReactElement[] = []
            let mapboxSourceIDs: string[] = []

            const storageFile = {
              key: rtFile.key,
              fileName: rtFile.fileName,
              author: rtFile.author,
              geoJSON: blobJSON
            } as StorageFile

            this.setState({ storageFiles: this.state.storageFiles.concat([storageFile]) })

            blobJSON.features.forEach((feature, featureIdx) => {

              if (feature.geometry.type === "LineString") {

                const lineColor = feature.properties && feature.properties.strokeColor
                  ? feature.properties.strokeColor
                  : this.hexColorFromString("green")

                const lineName = feature.properties && feature.properties.name
                  ? feature.properties.name
                  : ''

                const lineStringGeoJSON = {
                  "features": [{
                  "type": "Feature",
                  "properties": {
                      "name": lineName,
                      "strokeColor": lineColor
                  },
                  "geometry": {
                    "type": "LineString",
                    "coordinates": feature.geometry.coordinates
                  }
                }],
                  "type": "FeatureCollection"
                } as FeatureCollection

                const sourceID = `file-line-string${rtIdx}${featureIdx}`

                const lineStringLayerStyle = {
                  id: sourceID,
                  type: "line",
                  paint: {
                    'line-width': 3,
                    'line-color': lineColor
                  }
                } as LineLayer

                const geoJSONElement = 
                  <Source 
                    id={sourceID}
                    type="geojson" 
                    data={lineStringGeoJSON} 
                    key={`file-line-string${rtIdx}${featureIdx}`}
                  >
                    <Layer {...lineStringLayerStyle} />
                  </Source>

                mapboxSourceIDs.push(sourceID)
                fileElements.push(geoJSONElement)
              }

            })

            const idx = blobJSON.features.length
            const sourceID = `file-circle${rtIdx}`

            const symbolLayerStyle = {
              id: sourceID,
              type: "symbol",
              layout: {
                'icon-image': 'mappin',
                'icon-size': 0.35,
                'icon-anchor': 'bottom',
                'text-field': ['get', 'name'],
                "text-size": 18,
                "text-anchor": "top",
              },
              paint: {
                'text-halo-width': 4,
                'text-halo-color': '#FFFFFF'
              }
            } as SymbolLayer

            const circleElement = 
              <Source 
                id={sourceID}
                type="geojson" 
                data={blobJSON} 
                key={sourceID}
              >
                <Layer {...symbolLayerStyle} />
              </Source>

            mapboxSourceIDs.push(sourceID)
            fileElements.push(circleElement)

            this.setState({ 
              mapboxSourceIDs: this.state.mapboxSourceIDs.concat(mapboxSourceIDs),
              geoJSONElements: this.state.geoJSONElements.concat(fileElements) 
            })

          })
        })

    })
  // }
  }

  public render() {

    const { teamName, skyLayer, geoJSONElements, featureElements, teammateElements } = this.state;

    return (
      <div className="App">
        <div className="map-container">
          <ReactMapGL
            mapboxAccessToken='pk.eyJ1IjoiYmNhbGRlciIsImEiOiJja3JjYmZxdDQzNDVnMm9wZDA4bzB0N3cxIn0.2zPuxUWOavL-WPmtm8tmTw'
            initialViewState={{
              longitude: -113.54794023980436,
              latitude: 28.004455076243286,
              zoom: 6
            }}
            mapStyle="mapbox://styles/bcalder/clmsgxxit05bk01qb00ji4hp4"
            terrain={{
              'source': 'mapbox-dem', 
              'exaggeration': 1.5 
            }}
            onLoad={this.onMapLoad}
            // onStyleData={this.onMapLoad}
            ref={this.mapRef}
          >
            <Source 
              id="mapbox-dem" 
              type="raster-dem" 
              url='mapbox://mapbox.mapbox-terrain-dem-v1'
              tileSize={512}
              maxzoom={15}
            />
            <Layer {...skyLayer} />
            <NavigationControl/>
            <GeolocateControl/>
            <ScaleControl/>
            {geoJSONElements}
            {featureElements}
            {teammateElements}
          </ReactMapGL>
        </div>
        <div className="team-name">
          <h1>{teamName}</h1>
        </div>
      </div>
    )
  }
}

export default App;

// export default Home;

// const router = createBrowserRouter([
//   { path: "/", Component: Map },
//   {
//     // Lifted blog splat route
//     path: "/maps/*",
//     children: [
//       // New blog index route
//       { index: true, Component: () => <h1>Blog Index</h1> },
//       // Blog subapp splat route added for /blog/posts matching
//       { path: "*", Component: Home },
//     ],
//   },
//   { path: "*", Component: Root },
// ]);

// // 4️⃣ RouterProvider added
// export default function App() {
//   return <RouterProvider router={router} />;
// }

// function Root() {
//   // 2️⃣ `BrowserRouter` component removed, but the <Routes>/<Route>
//   // component below are unchanged
//   return (
//     <Routes>
//       <Route path="/users/*" element={<UserApp />} />
//     </Routes>
//   );
// }

// function Map() {
//   return (
//     <Routes>
//       <Route index element={<h1>Map Home</h1>} />
//       <Route path="posts" element={<h1>Map Posts</h1>} />
//     </Routes>
//   );
// }

// function UserApp() {
//   return (
//     <Routes>
//       <Route index element={<h1>Users Index</h1>} />
//     </Routes>
//   );
// }